import React, { Component } from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import AOS from "aos"
import 'aos/dist/aos.css';
import NewsStrip from "../components/news-strip";

class JobsPage extends Component {

  componentDidMount() {
    AOS.init();
    window.addEventListener('load', AOS.refresh);
    // @NOTE: Hack to force AOS to work :(
    window.setTimeout(AOS.refresh, 1000);
  }

  componentDidUpdate() {
    AOS.refresh();
  }

  render() {
    return (
      <Layout>
        <SEO title="Jobs | PCV Driver" description="PCV Driver? Find your next career at Tetley's Coaches - Leeds' oldest coach operator established 1949." />
        <div className="block-services inner-body-bg">
          <div className="body gutter page-content-parent headblock">
            <h1 className="headblock__heading">Jobs</h1>
            <p className="headblock__byline">Find a career with Tetley's Coaches</p>
          </div>
          <div className="body gutter page-content-parent">
            <h2 data-aos='fade-left' data-aos-duration="500">PCV Driver</h2>
            <div className="page-content">
              <div className="page-content__text">
                <p>Tetley's coaches currently have vacancies at its Leeds depot for experienced bus/coach drivers to deliver private hire, contract and school bus services.</p>
                <p>Applicants must hold a PCV licence with category D entitlement and have a CPC qualification card.</p>
                <p>They will also be required to undergo an enhanced DBS check as the post will involve working with children.</p>

                <h3>Rate of pay:</h3>
                <br></br>

                <p>Monday to Friday £13.50 + £1 per hour summer bonus</p>
                <p>Saturday and Sunday £15 + £1 per hour summer bonus</p>
                <p>Summer bonus scheme to run in May, June and July</p>

                <p><strong>Bonuses</strong></p>
                  <ul>
                    <li>Qualifying Rugby jobs £25 day bonus</li>
                    <li>Qualifying Football Jobs £50 day bonus</li>
                    <li>Bank Holidays – Weekend rate applies</li>
                    <li>Boxing Day/New Years Day £75 in addition to the weekend rate</li>
                    <li>Overnight allowance £25 tax free</li>
                  </ul>

                <h3>Contractual hours:</h3>
                <br></br>
                <p><strong>Full time</strong>: 35 hours a week + overtime (Overtime is paid weekly)</p>
                <p><strong>Part time</strong>: 25 hours per week + overtime (Overtime is paid weekly)</p>
                <p><strong>Casual</strong>: Zero hours</p>

                <p>35 hours a week + overtime (Overtime is paid weekly)</p>

                <p>Employee benefits:</p>

                <ul>
                  <li>Enhanced holiday pay</li>
                  <li>Paid medicals</li>
                  <li>Annual driver CPC training</li>
                  <li>Company pension scheme</li>
                  <li>Smart uniform</li>
                  <li>Modern fleet</li>
                </ul>

              </div>
              <div className="page-content__text">
                <div className="content-box">
                  <h3>How to apply</h3>
                  <p>Please complete our online <a href="https://forms.gle/xbR2G7PPAhZPLe7K8">Application form</a>.</p>
                  <p>Applicants may also email a copy of their CV to <a href="mailto:sales@tetleyscoaches.co.uk">sales@tetleyscoaches.co.uk</a> along with a cover letter.</p>
                  <p>Immediate start available.</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <NewsStrip />

      </Layout>
    );
  }
}

export default JobsPage
